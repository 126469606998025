import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import {
  IMarketplaceCreator,
  IMarketplaceItem,
  IMarketplaceUse,
  MarketplaceCreator,
  MarketplaceItem,
  MarketplaceUse,
} from '../shared/models/marketplace.model';
import { map } from 'rxjs';
import { MainLanguageService } from './main-language.service';
import { LanguagesEnum } from '../shared/enums/app-languages.enum';
import { IMarketplaceFilters } from '../shared/models/filters.model';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private currentLanguage?: string;

  constructor(private readonly apiService: ApiService, private readonly mainLanguageService: MainLanguageService) {
    this.mainLanguageService.defaultLanguage$.subscribe({
      next: (l) => (this.currentLanguage = l),
    });
  }

  getMarketplaceReal$(params: string, items: number, page: number, query?: IMarketplaceFilters) {
    return this.apiService
      .get<{ items: IMarketplaceItem[]; total: number }>(
        `marketplace?items=${items}&page=${page}${params}${query?.search ? `&q=${query.search}` : ''}&lang=${this.currentLanguage}`
      )
      .pipe(map(({ items, total }) => ({ items: items.map((item) => new MarketplaceItem(item)), total })));
  }

  getMarketplaceElementById$(id: string) {
    return this.apiService.get<IMarketplaceItem>(`marketplace/${id}?lang=${this.currentLanguage}`).pipe(map((item) => new MarketplaceItem(item)));
  }

  getLatestElements$(itemsCount: number) {
    return this.apiService
      .get<{ items: IMarketplaceItem[]; total: number }>(`marketplace?items=${itemsCount}&page=0&promo=true&lang=${this.currentLanguage}`)
      .pipe(map(({ items, total }) => ({ items: items.map((item) => new MarketplaceItem(item)), total })));
  }

  getMarketplaceItemsByCreator$(creatorId: string, items: number, page: number) {
    return this.apiService
      .get<{ items: IMarketplaceItem[]; total: number }>(
        `marketplace?creatorId=${creatorId}&items=${items}&page=${page}&lang=${this.currentLanguage}`
      )
      .pipe(map(({ items, total }) => ({ items: items.map((item) => new MarketplaceItem(item)), total })));
  }

  getMarketplaceCreator$(creatorId: string) {
    return this.apiService.get<IMarketplaceCreator>(`marketplace/creator/${creatorId}`).pipe(map((creator) => new MarketplaceCreator(creator)));
  }

  useAssistant$(marketplaceId: string) {
    return this.apiService.post<IMarketplaceUse>(`marketplace/${marketplaceId}/use`, {}).pipe(map((res) => new MarketplaceUse(res)));
  }
}
