import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { MainLanguageService } from './main-language.service';
import { map, of, tap } from 'rxjs';
import { AIModel, IAIModel } from '../shared/models/ai-models.model';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class AiModelsService {
  private currentLang?: string;

  aiModels: AIModel[] = [];

  constructor(
    private readonly apiService: ApiService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly cacheService: CacheService
  ) {
    this.mainLanguageService.defaultLanguage$.subscribe({
      next: (lang) => (this.currentLang = lang),
    });
  }

  getAllModels$() {
    const url = `ai-models?lang=${this.currentLang}&showAll=true`;
    const cachedModels = this.cacheService.get(url);

    if (cachedModels) {
      return of(cachedModels);
    }

    return this.apiService.get<{ items: IAIModel[]; total: number }>(url).pipe(
      map(({ items }) => items.map((model) => new AIModel(model))),
      tap((models) => {
        this.cacheService.set(url, models, 3600000); // Cache for 1 hour
      })
    );
  }

  getModelById$(id: string) {
    return this.apiService.get<IAIModel>(`ai-models/${id}?lang=${this.currentLang}&items=100&showAll=false`).pipe(map((model) => new AIModel(model)));
  }
}
