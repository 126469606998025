import { DateTime } from 'luxon';
import { AssistantUsePermission } from './assistant-use.model';
import { range } from 'lodash';
import { AiModelCapabilities } from './ai-models.model';

export type MarketplaceCategories = 'official' | 'legacy' | 'promo' | 'using';

export enum MarketplaceStatus {
  QUALIFIED = 'QUALIFIED',
  UNQUALIFIED = 'UNQUALIFIED',
  PROPOSAL = 'PROPOSAL',
  NEW = 'NEW',
}

export interface IMarketplaceUse {
  marketplaceId: string;
  userId: string;
  archived: boolean;
  permission: AssistantUsePermission;
  chatBotId: string;
  lastUseTimestamp: string;
  chatGroupId: string | null;
  id: string;
}

export class MarketplaceUse {
  marketplaceId: string = '';
  userId: string = '';
  archived: boolean = false;
  permission: AssistantUsePermission = 'USE';
  chatBotId: string = '';
  lastUseTimestamp: DateTime = DateTime.now();
  chatGroupId: string | null = null;
  id: string = '';

  constructor(res?: IMarketplaceUse) {
    if (res) {
      this.marketplaceId = res.marketplaceId;
      this.userId = res.userId;
      this.archived = res.archived;
      this.permission = res.permission;
      this.chatBotId = res.chatBotId;
      this.lastUseTimestamp = DateTime.fromISO(res.lastUseTimestamp);
      this.chatGroupId = res.chatGroupId;
      this.id = res.id;
    }
  }
}

interface IMarketplaceItemLoaders {
  isAdding: boolean;
}

export interface ICurrentTagFilters {
  using: boolean;
  official: boolean;
  legacy: boolean;
  promo: boolean;
}

export interface ICartItem {
  item: MarketplaceItem;
  quantity: number;
}

export class CartItem {
  item?: MarketplaceItem;
  quantity: number = 0;

  constructor(res?: ICartItem) {
    if (res) {
      this.item = res.item;
      this.quantity = res.quantity;
    }
  }

  addQuantity() {
    this.quantity++;
  }

  removeQuantity() {
    this.quantity--;
  }
}

export interface IStoreCard {
  id: string;
  language: string;
  name: string | null;
  description: string;
  longDescription: string;
  featureGraphic: string | null;
  previewImages: string[] | null;
  welcomeMessage: string;
}

export class StoreCard {
  id: string = '';
  language: string = '';
  name: string | null = null;
  description: string = '';
  longDescription: string = '';
  featureGraphic: string | null = null;
  previewImages: string[] | null = null;
  welcomeMessage: string = '';

  constructor(res?: IStoreCard) {
    if (res) {
      this.id = res.id;
      this.language = res.language;
      this.name = res.name;
      this.description = res.description;
      this.longDescription = res.longDescription;
      this.featureGraphic = res.featureGraphic;
      this.previewImages = res.previewImages;
      this.welcomeMessage = res.welcomeMessage;
    }
  }
}

interface IMarketplaceItemCreator {
  id: string;
  name: string;
  image: string | null;
}

class MarketplaceItemCreator {
  id: string = '';
  name: string = '';
  image: string = 'assets/layout/images/avatar.png';

  constructor(res?: IMarketplaceItemCreator) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      if (res.image) this.image = res.image;
    }
  }
}

interface IMarketplaceItemCost {
  message: number;
  includedK: number;
  extraK: number;
}

interface IMarketplaceItemFeature {
  nativeInternetAccess: boolean;
  urlRetrieval: boolean;
  urlRetrievalMode: 'EXTRACT' | 'HTML';
}

interface IMarketplaceItemRating {
  rating: number;
  reviews: number;
}

export interface IMarketplaceItem {
  id: string;
  name: string;
  description: string;
  capabilities: AiModelCapabilities[];
  thumbnailImage: string | null;
  imageUuid?: string;
  status: MarketplaceStatus;
  creator: IMarketplaceItemCreator;
  createdAt: string;
  updatedAt: string;
  official: boolean;
  legacy: boolean;
  promo: boolean;
  version: string;
  using: boolean;
  cost: IMarketplaceItemCost;
  feature: IMarketplaceItemFeature;
  rating: IMarketplaceItemRating;
  storeCard?: IStoreCard;
}

export class MarketplaceItem {
  id: string = '';
  name: string = '';
  description: string = '';
  capabilities: AiModelCapabilities[] = [];
  imageUuid?: string;
  status?: MarketplaceStatus;
  creator?: MarketplaceItemCreator;
  createdAt: DateTime = DateTime.now();
  updatedAt: DateTime = DateTime.now();
  official: boolean = false;
  legacy: boolean = false;
  promo: boolean = false;
  version: string = '';
  using: boolean = false;
  cost?: IMarketplaceItemCost;
  feature?: IMarketplaceItemFeature;
  rating?: IMarketplaceItemRating;
  storeCard?: StoreCard;

  loaders: IMarketplaceItemLoaders = {
    isAdding: false,
  };

  constructor(res?: IMarketplaceItem) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      this.description = res.description;
      this.capabilities = res.capabilities;
      if (res.imageUuid) this.imageUuid = res.imageUuid;
      this.status = res.status;
      this.creator = new MarketplaceItemCreator(res.creator);
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.updatedAt = DateTime.fromISO(res.updatedAt);
      this.official = res.official;
      this.legacy = res.legacy;
      this.promo = res.promo;
      this.version = res.version;
      this.cost = res.cost;
      this.feature = res.feature;
      this.rating = res.rating;
      this.using = res.using;
      this.storeCard = new StoreCard(res.storeCard);
    }
  }

  setLoaderValue(key: keyof IMarketplaceItemLoaders, value: boolean) {
    this.loaders[key] = value;
  }

  setUsing(value: boolean) {
    this.using = value;
  }
}

interface MarketplaceCreatorCompany {
  id: string;
  name: string;
  level: number;
  permissions: null;
}

export interface IMarketplaceCreator {
  id: string;
  name: string;
  surname: string;
  username: string;
  language: string;
  company: MarketplaceCreatorCompany;
  image: string | null;
}

export class MarketplaceCreator {
  id: string = '';
  name: string = '';
  surname: string = '';
  username: string = '';
  language: string = '';
  company?: MarketplaceCreatorCompany;
  image: string | null = null;

  constructor(res?: IMarketplaceCreator) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      this.surname = res.surname;
      this.username = res.username;
      this.language = res.language;
      this.company = res.company;
      this.image = res.image;
    }
  }
}
