import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Settings } from '../shared/enums/settings.enum';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly apiService: ApiService) {}

  getUserSetting(id: string) {
    return this.apiService.get<{ [key: string]: any }>(`settings/user?availableSettingId=${id}`);
  }

  updateUserSetting(id: string, value: { [key: string]: any }) {
    return this.apiService.put<any>(`settings/user`, [
      {
        availableSettingId: id,
        value,
      },
    ]);
  }

  getSettingBySettingId(id: string) {
    return this.apiService.get<any>(`settings/company?availableSettingId=${id}`);
  }

  getGroupSetting<T extends { [key in Settings]?: any }>(id: string, assistantId?: string) {
    return this.apiService.get<T>(`settings/company?settingGroupId=${id}${assistantId ? `&assistantId=${assistantId}` : ''}`);
  }

  updateCompanySetting(id: string, body: any) {
    return this.apiService.put<any>(`settings/company`, [
      {
        availableSettingId: id,
        value: body,
      },
    ]);
  }

  updateMultipleCompanySettingsForAssistant(assistantId: string, settingId: string, body: any[]) {
    return this.apiService.put<any>(`settings/company`, body);
  }

  updateCompanySettingForAssistant(assistantId: string, settingId: string, body: any) {
    return this.apiService.put<any>(`settings/company`, [
      {
        availableSettingId: settingId,
        value: body,
        assistantId,
      },
    ]);
  }
}
