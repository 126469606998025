import { iUser, UserCore } from 'src/app/core/models/userCore.model';
import { LanguagesEnum } from '../enums/app-languages.enum';
import { DateTime } from 'luxon';
import { UserVerified } from '../enums/user-verified.enum';
import { environment } from 'src/environments/environment';

export enum ORG_FEATURES_SECTIONS {
  MARKETPLACE = 'MARKETPLACE',
  IMAGE_GENERATION = 'IMAGE_GENERATION',
}
export enum ORG_FEATURES_ACTIONS {
  ASSISTANT_TAGGING = 'ASSISTANT_TAGGING',
  PRIVATE_CONVERSATION = 'PRIVATE_CONVERSATION',
  SHARE_CONVERSATION = 'SHARE_CONVERSATION',
}

export class OrgFeatures {
  sections?: {
    [key in ORG_FEATURES_SECTIONS]: boolean;
  };
  functions?: {
    [key in ORG_FEATURES_ACTIONS]: boolean;
  };

  constructor(res?: OrgFeatures) {
    if (res) {
      this.sections = res.sections;
      this.functions = res.functions;
    }
  }
}

export class UserOrgSettings {
  ORG_FEATURES?: OrgFeatures;

  constructor(orgSettings?: UserOrgSettings) {
    if (orgSettings) this.ORG_FEATURES = new OrgFeatures(orgSettings.ORG_FEATURES);
  }
}

export interface IUserOnboarding {
  name: string;
  language: LanguagesEnum;
  surname: string;
  nickname: string;
  birthDate: string;
}

export interface IUser<T = any, W = any> extends iUser {
  image: string;
  username: string;
  language?: LanguagesEnum;
  description?: string;
  orgSettings?: UserOrgSettings;
  verified: UserVerified;
  nickname?: string;
  birthDate?: string;
}

/**Model User */
export class User<T = any, W = any> extends UserCore<T, W> {
  image: string = '';
  username: string = '';
  language?: LanguagesEnum;
  description?: string = '';
  orgSettings?: UserOrgSettings;
  verified?: UserVerified;
  nickname: string | null = null;
  birthDate: DateTime | null = null;
  hasToOnboard: boolean = false;

  /** @ignore */
  constructor(user: IUser<T, W>) {
    super(user);

    this.image = user.image;
    this.username = user.username;
    this.language = user.language;
    this.description = user.description;
    this.orgSettings = new UserOrgSettings(user.orgSettings);
    this.verified = user.verified;
    if (user.birthDate) this.birthDate = DateTime.fromISO(user.birthDate);
    if (user.nickname) this.nickname = user.nickname;
    this.hasToOnboard = !this.birthDate || !this.nickname || !this.name || !this.surname;
  }

  get isVerified() {
    return environment.production || this.verified !== UserVerified.NONE;
  }
}

export interface ICompanyUser {
  companyId: string;
  id: string;
  username: string;
  anagrafica: {
    name: string;
    surname: string;
  };
}

export class CompanyUser {
  companyId: string = '';
  id: string = '';
  username: string = '';
  anagrafica: {
    name: string;
    surname: string;
  } = {
    name: '',
    surname: '',
  };

  constructor(res?: ICompanyUser) {
    if (res) {
      this.companyId = res.companyId;
      this.id = res.id;
      this.username = res.username;
      this.anagrafica = res.anagrafica;
    }
  }
}
