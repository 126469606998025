import { DateTime } from 'luxon';
import { ResponseLength } from '../types/response-length.type';
import { BotPreview, IBotPreview } from './bot-preview.model';
import { AiModelCapabilities } from './ai-models.model';

export interface IHasStartedChat {
  conversationId: string;
  assistant: ChatBot;
}

export interface ICreateAssistantGroupDTO {
  name: string;
  moderatorIds: string;
  chatBotIds: string[];
}

interface IAssistantUses {
  id: string;
  archived: boolean;
  lastUseTimestamp: string | null;
  chatBotId: string;
  marketplaceId: string | null;
  userId: string;
}

class AssistantUses {
  id: string = '';
  archived: boolean = false;
  lastUseTimestamp: DateTime | null = null;
  chatBotId: string = '';
  marketplaceId: string | null = null;
  userId: string = '';

  constructor(res?: IAssistantUses) {
    if (res) {
      this.id = res.id;
      this.archived = res.archived;
      if (res.lastUseTimestamp) this.lastUseTimestamp = DateTime.fromISO(res.lastUseTimestamp);
      this.chatBotId = res.chatBotId;
      this.marketplaceId = res.marketplaceId;
      this.userId = res.userId;
    }
  }
}

export interface IChatBot {
  id: string;
  active: boolean;
  name: string;
  description: string;
  capabilities: AiModelCapabilities[];
  embeddingModel: string;
  // assistantUses: IAssistantUses[];
  lastUseTimestamp: string | null;
  updatedAt: string;
  apiKey: string;
  domain: string;
  imageUuid: string | null;
  failureMessage: string;
  starterMessage: string;
  welcomeMessage: string;
  type: 'ASSISTANT' | 'MARKETPLACE' | 'GROUP';
  model: string;
  vendor: string;
  language: string;
  status: string;
  companyId: string;
  creatorId: string;
  responseLength: ResponseLength;
  replyMode: 'open' | 'closed' | 'hybrid';
  company: IChatBotConfiguration;
  chatBotConfiguration: IChatBotConfiguration;
  chatBotPreview: IBotPreview | undefined;
  urlNavigation: boolean;
  urlNavigationExtractText: boolean;
  costExtraK: string;
  costIncludedK: string;
  costMessage: string;
}

export interface IChatBotConfiguration {
  id: string;
  onboardingForm?: string;
  description?: string;
}

export type AssistantType = 'ASSISTANT' | 'MARKETPLACE' | 'GROUP';

export class ChatBot {
  id: string = '';
  active: boolean = false;
  name: string = '';
  updatedAt: DateTime = DateTime.now();
  embeddingModel: string = '';
  capabilities: AiModelCapabilities[] = [];
  lastUseTimestamp: DateTime | null = null;
  description: string = '';
  apiKey: string = '';
  domain: string = '';
  language: string = '';
  type?: AssistantType;
  imageUuid: string | null = null;
  status: string = '';
  companyId: string = '';
  creatorId: string = '';
  selected: boolean = false;
  failureMessage: string = '';
  starterMessage: string = '';
  welcomeMessage: string = '';
  model: string = '';
  vendor: string = '';
  responseLength: ResponseLength = 'SHORT';
  replyMode: 'open' | 'closed' | 'hybrid' = 'open';
  company: IChatBotConfiguration = { id: '' };
  chatBotConfiguration: {
    onBoardingForm?: any[];
    // onBoardingConfigFormGroup: FormGroup<{}>;
  } = {};
  chatBotPreview?: BotPreview;
  urlNavigation: boolean = false;
  urlNavigationExtractText: boolean = false;
  pinned: boolean = false;
  costExtraK: string = '';
  costIncludedK: string = '';
  costMessage: string = '';

  constructor(data?: Partial<IChatBot>) {
    if (data) {
      if (data.id) this.id = data.id;
      if (data.active) this.active = data.active;
      if (data.name) this.name = data.name;
      if (data.description) this.description = data.description;
      if (data.updatedAt) this.updatedAt = DateTime.fromISO(data.updatedAt);
      if (data.capabilities) this.capabilities = data.capabilities;
      if (data.embeddingModel) this.embeddingModel = data.embeddingModel;
      if (data.lastUseTimestamp) this.lastUseTimestamp = DateTime.fromISO(data.lastUseTimestamp);
      if (data.apiKey) this.apiKey = data.apiKey;
      if (data.domain) this.domain = data.domain;
      if (data.language) this.language = data.language;
      if (data.type) this.type = data.type;
      if (data.imageUuid) this.imageUuid = data.imageUuid;
      if (data.status) this.status = data.status;
      if (data.companyId) this.companyId = data.companyId;
      if (data.creatorId) this.creatorId = data.creatorId;
      if (data.failureMessage) this.failureMessage = data.failureMessage;
      if (data.starterMessage) this.starterMessage = data.starterMessage;
      if (data.welcomeMessage) this.welcomeMessage = data.welcomeMessage;
      if (data.model) this.model = data.model;
      if (data.vendor) this.vendor = data.vendor;
      if (data.responseLength) this.responseLength = data.responseLength;
      if (data.replyMode) this.replyMode = data.replyMode;
      if (data.company) this.company = data.company;
      if (data.chatBotConfiguration)
        this.chatBotConfiguration = {
          onBoardingForm: JSON.parse(data.chatBotConfiguration?.onboardingForm ?? '[]'),
          // onBoardingConfigFormGroup: checkIfOnboardingFormIsValid(data.chatBotConfiguration?.onboardingForm ?? '[]', new ValidatorsService()),
        };
      if (data.chatBotPreview) this.chatBotPreview = new BotPreview(data.chatBotPreview);
      if (data.urlNavigation) this.urlNavigation = data.urlNavigation;
      if (data.urlNavigationExtractText) this.urlNavigationExtractText = data.urlNavigationExtractText;
      if (data.costExtraK) this.costExtraK = data.costExtraK;
      if (data.costIncludedK) this.costIncludedK = data.costIncludedK;
      if (data.costMessage) this.costMessage = data.costMessage;
    }
  }

  setPinned(pinned: boolean) {
    this.pinned = pinned;
  }
}

export interface ICreateBotDTO {
  enabled: boolean;
  starterMessage: string;
  failureMessage: string;
  welcomeMessage: string;
  replyMode: string;
  description: string;
  language: string;
  vendor: string;
  //enabled: this.formGeneral.get('enabled').value,
  image: string;
  model: string;
  botName: string;
  name: string;
  status: string;
  companyId: string;
  domain: string;
  responseLength: string;
  urlNavigation: boolean;
  urlNavigationExtractText: boolean;
}

export interface IBotsMenuItem {
  name?: string;
  code?: string;
  icon?: string;
  role?: string;
  image?: string;
  model?: string;
  type?: AssistantType;
}

export interface IOmnibox {
  botId: string;
  startingMessage: string;
  files?: File;
}
