export enum Settings {
  FAVOURITES = 'U_FAVOURITE_CONVERSATION',
  KB_UI_OPTIONS = 'ASSISTANT_KB_UI_OPTIONS',
  ASSISTANT_SHOW_KB_MATCH = 'ASSISTANT_SHOW_KB_MATCH',
  ASSISTANT_SHOW_KB_RESOURCES = 'ASSISTANT_SHOW_KB_RESOURCES',
  ASSISTANT_KB_RESOURCES_DOWNLOAD = 'ASSISTANT_KB_RESOURCES_DOWNLOAD',
  ASSISTANT_MARKETING_TOOLS = 'ASSISTANT_MARKETING_TOOLS',
  ASSISTANT_SETTINGS = 'ASSISTANT_SETTINGS',
  A_LEAD_GENERATION_FORM = 'A_LEAD_GENERATION_FORM',
  A_NEW_LEADS_MAIL = 'A_NEW_LEADS_MAIL',
  ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS',
  ORG_FEATURES = 'ORG_FEATURES',
  ASSISTANT_ANONYMOUS_SESSIONS = 'ASSISTANT_ANONYMOUS_SESSIONS',
  ASSISTANT_ATTACHMENT = 'ASSISTANT_ATTACHMENT',
  ASSISTANT_CAN_TAG = 'ASSISTANT_CAN_TAG',
  ASSISTANT_CHAT_VISIBILITY = 'ASSISTANT_CHAT_VISIBILITY',
}
